<template>

  <ul class="navbar-nav ">
    <!-- {{ currentRouteName }}
    {{ currentRouteClassGlossary }} -->
    <li class="nav-item">
      <a :class="currentRouteClassHome" href="/">HOME</a> 
    </li>
    <li class="nav-item">
      <a :class="currentRouteClassApp" href="/app/">APP</a>
    </li>
    <li class="nav-item">
      <a :class="currentRouteClassGlossary" href="/glosario">GLOSARIO</a>
    </li>
    <li class="nav-item">
      <a :class="currentRouteClassGuide" href="/manual/">MANUAL</a>
    </li>

  </ul>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: "TopNavBarSelector",
  props:{
    selectedId: {
      type: Number, // Define the type of the parameter (in this case, String)
      required: true // Specify if the parameter is required or not
    }
  },
  computed: {
    currentRouteName() {
      const router = useRouter();
      const currentRoute = router.currentRoute.value;
      return currentRoute.name;
    },
    currentRouteClassHome() {
      return this.currentRouteName === 'Home' ? 'nav-link active' : 'nav-link'
    },
    currentRouteClassGuide() {
      return this.currentRouteName === 'Guide' ? 'nav-link active' : 'nav-link'
    },
    currentRouteClassGlossary() {
      return (this.currentRouteName === 'Glossary' || this.currentRouteName === 'Search') ? 'nav-link active' : 'nav-link'
    },
    currentRouteClassApp() {
      return this.currentRouteName === 'App' ? 'nav-link active' : 'nav-link'
    }
  }
}
</script>

<style scoped>

</style>