<template>
  <span class="spirits-zone-custom-font-1">SPIRITS</span><span class="spirits-zone-custom-font-2">.ZONE</span>
</template>

<script>
export default {
  name: "SpiritsZoneLogo.vue"
}
</script>

<style scoped>

</style>