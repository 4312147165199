<template>
  <!--Waves Container-->
  <div id="bottomSpacer" style="min-height: 5rem;"></div>
  <div class="fixed-bottom m-0 mt-3" >
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(242,203,84,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="2" fill="rgba(255,255,255,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="4" fill="rgba(242,203,84,0.2)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.1)" />
      </g>
    </svg>
  </div>
  <div class="fixed-bottom m-0 mt-3" >
    <div class="d-flex" style="font-style: italic">
      <div class="m-auto me-2" style="color: var(--main-color-1-2)">
        <!-- <span>Terms of Use - </span>
        <span>Privacy Policy - </span> -->
        <!--<span><a href="//www.dmca.com/Protection/Status.aspx?ID=2d8ffcee-d141-4af4-adc0-a8dd20ba8deb" title="DMCA.com Protection Status" class="dmca-badge"> DM&copy;A </a></span>
        -->
        <span>
          <!-- //www.dmca.com/Protection/Status.aspx?ID=2d8ffcee-d141-4af4-adc0-a8dd20ba8deb -->
          <a href="https://www.dmca.com/r/r2j6ydz" title="DMCA.com Protection Status" class="dmca-badge">
            <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120x.png?ID=2d8ffcee-d141-4af4-adc0-a8dd20ba8deb"  alt="DMCA.com Protection Status" style="width: 1rem; height: auto;" />
          </a>
        </span>
        <span style="font-style: italic; font-size: 0.5rem;">
          &copy;
          2023 - <a href="https://carlosfalguera.com">Carlos Falguera</a> </span>
      </div>
    </div>
  </div>
  <!--Waves end-->
</template>

<script>
export default {
  name: "BackgroundWaves",
  setup() {
    const dmcaScript = document.createElement("script");
    dmcaScript.setAttribute(
        "src",
        "https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
    );
    document.head.appendChild(dmcaScript);
  }
}
</script>

<style scoped>

</style>