import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {   path: '/',  name: 'Home', meta: { breadcrumb: 'Home'},
        component: () => import('@/views/HomeView.vue'),
    },
    {   path: '/search',  name: 'Search', meta: { breadcrumb: 'Search'},
        component: () => import('@/views/SearchView.vue'),
        props: (route) => ({ queryParam: route.query.q, termParam: route.query.t })
    },
    {   path: '/manual', name: 'Guide', meta: {breadcrumb: 'Guide'},
        component: () => import('@/views/GuideView.vue')
    },
    {   path: '/glosario', name: 'Glossary', meta: { breadcrumb: 'Glossary' },
        component: () => import('@/views/GlossaryView.vue')
    },
    {   path: '/app', name: 'App', meta: {breadcrumb: 'App' },
        component: () => import('@/views/AppView.vue')
    }/*
    ,
    {   path: '/*', name: 'NF', meta: {breadcrumb: 'NF' },
        component: () => import('@/views/NotFoundView.vue')
    }*/
    // ,
    // {   path: '*', // Wildcard route for 404 page
    //     component: () => import('@/views/NotFoundView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // Update the document title based on the current route
    const pageTitle = "Spirits Zone";//to.meta?.title || 'Default Title';
    document.title = pageTitle; //toTitleCase ? toTitleCase(pageTitle) : pageTitle;
    next();
});

export default router
