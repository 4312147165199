// Vue.js
import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router'
//import store from '@/store'
import {BootstrapIconsPlugin} from 'bootstrap-icons-vue'

//import { createApp } from 'vue'
//import App from './App.vue'

// Bootstrap framework
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/plain_css/custom-bootstrap.css'

// Toasts and notification libraries
//import Toast, {POSITION} from "vue-toastification"
import Notifications from '@kyvg/vue3-notification'

// Breadcrumbs
import breadcrumbs from 'vue-3-breadcrumbs'

// LazyLoad
import VueLazyLoad from 'vue3-lazyload'

import './assets/css/SpiritsZone_Theme.scss' //plain_css/main.css

// custom js
//import Search from '@/assets/js/search.js';

import VueQRCodeComponent from 'vue-qrcode-component'

// 5. Create and mount the root instance.
const app = createApp(App);
app.use(router);
//app.use(store) --> go to pinia!
app.use(BootstrapIconsPlugin);
//app.use(Toast, {position: POSITION.BOTTOM_RIGHT});
app.use(Notifications);
app.use(breadcrumbs, {includeComponent: true});
app.use(VueLazyLoad, {});
app.component('qr-code', VueQRCodeComponent);

app.mount('#app')

//createApp(App).mount('#app')
