<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark m-auto">
    <div class="container m-auto">

      <div class="justify-content-center" id="navbarLogo">
        <a href="/"><SpiritsZoneLogo/></a>
      </div>
      <div class="justify-content-end" >
        <!-- <div class=" navbar-toggler">
          <span class="navbar-toggler-icon burguer-custom-style" ></span>
        </div> -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
          <!-- Hamburger Icon Markup -->
          <span class="navbar-toggler-icon burguer-custom-style" ></span>
        </button>
      </div>

      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <TopNavBarSelector :selectedId='1' />
      </div> 
      <!-- 
      <ul class="navbar-nav">
          <li class="nav-item nav-item-custom-wider-padding text-decoration-underline dropdown ">
            <a class="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              ENG
            </a>
            <div class="dropdown-menu" aria-labelledby="languageDropdown">
              <a class="dropdown-item" href="/esp/">ESP</a>
              <a class="dropdown-item" href="/cat/">CAT</a>
            </div>
          </li>
        </ul>
      -->

    </div>
  </nav>
</template>

<script>
import SpiritsZoneLogo from "@/components/common/SpiritsZoneLogo.vue";
import TopNavBarSelector from "@/components/common/TopNavBarSelector.vue";

export default {
  name: 'TopNavBar',
  components: {
    SpiritsZoneLogo, TopNavBarSelector
  },
  computed: {
    //...mapGetters(['getSequence', 'getUser'])
  },
  data() {
    return {
      srcLogo: require('@/assets/img/logo.png'),
      isBusy: true
    }
  },
  async created() {
    this.isBusy = false
  },
  methods: {
  }
}
</script>

<style scoped>
/* You can customize the style of the navbar using CSS */

</style>
