<script setup>
import BgImg from '@/assets/imgs/bg8-fullt.png';
</script>

<template>

  <div class="app-view">
    <img class="cover-bg"  :src="BgImg"  alt="" >
      <div class="col">
        <div  class="row m-auto"> <!-- fixed-top "> -->
          <TopNavBar />
        </div>
        <div class="row align-middle" style="padding: 1rem; display: flex; min-height: 70vh">
          <router-view />
        </div>
        <div>
          <BackgroundWaves/>
        </div>

        <!-- Simple default Notifier  <Notifications /> -->
        <notifications
            :duration="2500"
            animation-name="v-fade-left"
            position="bottom left"
        />
        <!-- Custom template Notifier, bottom right with fade down -->
        <notifications
            group="custom-template"
            :duration="2500"
            animation-name="v-fade-down"
            position="bottom right"
            :speed="1500"
        />
        <!-- Custom template Notifier, bottom right with fade left with down out -->
        <notifications
            classes="main-notification"
            group="main"
            :duration="5000"
            animation-name="v-fade-left"
            position="bottom right"
            :speed="1500"
        />

        <!-- Custom template Notifier, center with displaced down + fade up -->
        <notifications
            classes="main-notification-center"
            group="main-center"
            :duration="3500"
            animation-name="v-fade-up"
            position="top center"
            :speed="1500"
        />
        <!-- Notifiers with CSS custom animations
        <Notifications
            group="foo-css"
            position="bottom left"
            :speed="500"
        />
        Velocity animation example
        <Notifications
            group="foo-velocity"
            position="bottom right"
            animation-type="velocity"
            :speed="500"
        />

        Custom style example
        <notifications
            group="custom-style"
            position="top center"
            classes="n-light"
            :max="3"
            :width="400"
        />
        Full width example
        <notifications
            group="full-width"
            width="100%"
        />
        -->

      </div>
  </div>
  <!-- How to Comment html -->
</template>

<script>

import TopNavBar from "@/components/common/TopNavBar.vue";
import BackgroundWaves from "@/components/common/BackgroundWaves.vue";
//import 'jquery/dist/jquery.js';
import 'bootstrap/dist/js/bootstrap.js';


export default {
  name: 'App', //mixins: [mxSequence],
  components: {
    TopNavBar, BackgroundWaves
  },
  computed: {
    //...mapGetters(['getSequence', 'getUser'])
  },
  data() {
    return {
      isBusy: true,
      menuitem: 0
    }
  },
  async created() {
    this.isBusy = false
  },
  mounted() {
    this.showLoadInfo();
  },
  methods: {
    showLoadInfo(){
    }
  }
}
</script>

<style lang="scss">

</style>
